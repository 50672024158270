import { reactive } from "vue";

export var customers = reactive(
    [
        {id:"1",name:"Wipro",supplierNo:"5465", gst:"24 AAAAA00000A1Z5",address:"No 43/1, Mask Road, Frazer Town, Bengaluru-560005, Karnataka, India",
            contacts:[
                    {id:"12",name:"Dhanu",phoneno:"9845012345",designation:"Manager",email:"dhanu@bangaloreit.com"},
                    {id:"13",name:"Dev",phoneno:"9845054321",designation:"Regional Manager",email:"dev@bangaloreit.com"}
                    ]
        },
        {id:"2",name:"Titan Industries",supplierNo:"5465",gst:"24 AAAAA00000A1Z5",address:"93/2 , Behind Sharada Talkies, SP Rd, Bangalore",
            contacts:[
                        {id:"14",name:"Raj",phoneno:"9731398226",designation:"executive",email:""}
                    ]
        },
        {id:"3",name:"Cafe Cofee Day",supplierNo:"5465",gst:"24 AAAAA00000A1Z5",address:" Khans , No 13 (27/1) , 1st Floor , Serpentine Street , Near Asm Technologies , Richmond Town,Bangalore , Karnataka , 560025",
            contacts:[
                    {id:"15",name:"helpdesk",phoneno:"9880989654",designation:"manager",email:"info@gmail.com"}
                    ]
        }
    ])

export const getCustomerTemplate = function(){
    let customer = {id:"",name:"",supplierNo:"", gst:"",address:"",
                    contacts:[]
                    };
    return customer;
}

export const getContactTemplate = function(){
    let contact = {id:"",name:"",phoneno:"",designation:"",email:""};
    return contact;
}

export const listCustomers = function(){
    return customers;
}

export const addCustomer = function(customer){
    customers.push(customer);
}