import { reactive } from "vue";

export const states = reactive(
    [ 
        { name : "Andaman and Nicobar Islands",code:"AN"},
        { name : "Andhra Pradesh",code:"AP"},
        { name : "Arunachal Pradesh",code:"AR"},
        { name : "Assam",code:"AS"},
        { name : "Bihar",code:"BR"},
        { name : "Chandigarh",code:"CH"},
        { name : "Chhattisgarh",code:"CT"},
        { name : "Dadra and Nagar Haveli",code:"DN"},
        { name : "Daman and Diu",code:"DD"},
        { name : "Delhi",code:"DL"},
        { name : "Goa",code:"GA"},
        { name : "Gujarat",code:"GJ"},
        { name : "Haryana",code:"HR"},
        { name : "Himachal Pradesh",code:"HP"},
        { name : "Jammu and Kashmir",code:"JK"},
        { name : "Jharkhand",code:"JH"},
        { name : "Karnataka",code:"KA"},
        { name : "Kerala",code:"KL"},
        { name : "Lakshadweep",code:"LD"},
        { name : "Madhya Pradesh",code:"MP"},
        { name : "Maharashtra",code:"MH"},
        { name : "Manipur",code:"MN"},
        { name : "Meghalaya",code:"ML"},
        { name : "Mizoram",code:"MZ"},
        { name : "Nagaland",code:"NL"},
        { name : "Odisha",code:"OR"},
        { name : "Puducherry",code:"PY"},
        { name : "Punjab",code:"PB"},
        { name : "Rajasthan",code:"RJ"},
        { name : "Sikkim",code:"SK"},
        { name : "Tamil Nadu",code:"TN"},
        { name : "Telangana",code:"TG"},
        { name : "Tripura",code:"TR"},
        { name : "Uttar Pradesh",code:"UP"},
        { name : "Uttarakhand",code:"UT"},
        { name : "West Bengal",code:"WB"}
]);

var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

export const inWords =function(num) {
    if ((num = num.toString()).length > 9) return 'overflow';
    var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
    return str;
}

export const host = "http://localhost:8000/api/";

export default async function(url){
    let finalUrl= host+url;
    try{
        let res = await fetch(finalUrl);
        return await res.json();
    }catch(error){
        console.log(error);
    }
}